import React from "react"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
const SEO = ({ data }) => {
  const { href } = useLocation()
  
  return (
    <Helmet title={data?.title}>
      <html lang="en" />
      <link rel="canonical" href={href} />
      <meta charSet="utf-8" />
            {/* <!-- HTML Meta Tags --> */}
            {data?.title && <title>{data?.title}</title>}
      
      <meta name="description" content={data?.description}/>

      {/* <!-- Google / Search Engine Tags --> */}
      <meta property="name" content={data?.title}/>
      <meta property="description" content={data?.description}/>
      <meta property="image" content={data?.previewImage}/>

      {/* <!-- Facebook Meta Tags --> */}
      <meta property="og:url" content={href}/>
      <meta property="og:type" content="website"/>
      <meta property="og:title" content={data?.title}/>
      <meta property="og:description" content={data?.description}/>
      <meta property="og:image" content={data?.previewImage}/>

      {/* <!-- Twitter Meta Tags --> */}
      <meta property="twitter:card" content="summary_large_image"/>
      <meta property="twitter:title" content={data?.title}/>
      <meta property="twitter:description" content={data?.description}/>
      <meta property="twitter:image" content={data?.previewImage}/>
      <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1" />


      
      <link rel="icon" href="/favicon/favicon-32.png" sizes="32x32"/>
            <link rel="icon" href="/favicon/favicon-192.png" sizes="192x192"/>
            <link
                rel="apple-touch-icon"
                href="/favicon/favicon-152.png"
                sizes="152x152"
            />
            <link
                rel="apple-touch-icon"
                href="/favicon/favicon-167.png"
                sizes="167x167"
            />
            <link
                rel="apple-touch-icon"
                href="/favicon/favicon-180.png"
                sizes="180x180"
            />
            <link
                rel="shortcut icon"
                href="/favicon/favicon-196.png"
                sizes="196x196"
            />
     
    </Helmet>
  )
}

export default SEO
