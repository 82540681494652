import React, { useState, useEffect } from 'react';
import { ServiceHeading } from '@tradesorg-chris/component-library-waas-templates.template-2-service-heading';
import { nanoid } from 'nanoid';
import { FaqItem } from '@tradesorg-chris/component-library-waas-templates.template-2-faq-item';
import { useWindowDimensions } from '@utils/customHooks';

const FAQ = ({ keyword = "", data, className }) => {
 
    const { slogan, heading, faqs  } = data;
    const [faqState, setFaqState] = useState([]);
    const { width } = useWindowDimensions();

    useEffect(() => {
        setFaqState(faqs.map((item) => false));
    }, []);

    const handleState = (index, value) => {
        const prev = faqState;
        prev[index] = value;
        setFaqState([...prev]);
    };

    return (
        <div
            className={
                className ||
                'faqComponentWrapper px-5 md-1:px-10 xl:px-24 xs:pt-50px xs:pb-50px md-1:pb-64px xl:pb-70px md-1:pt-50px xl:pt-80px'
            }
            id="faq"
        >
            <div>
                <ServiceHeading
                    sloganText={slogan}
                    centered={width > 640}
                    heading={heading}
                    className={{
                        heading: 'mt-5px mb-3',
                        textWrapper: 'md-1:w-full max-w-2xl',
                    }}
                />
            </div>
            <div className="mt-0 xl:mx-52">
                {faqs.filter(item=> item.question.toLowerCase().includes(keyword.toLowerCase())).slice(0,10).map((item, index) => (
                    <FaqItem
                        key={nanoid()}
                        title={item.question}
                        content={item.answer}
                        active={faqState[index]}
                        handleState={handleState}
                        index={index}
                    />
                ))}
            </div>
        </div>
    );
};
export default FAQ;
